.login_form_page {
    background: rgba(2, 0, 36, 1);
    background: radial-gradient(circle, rgba(2, 0, 36, 1) 0%, rgba(0, 0, 0, 1) 100%);

    flex: 1;
    display: flex;
    align-items: center;
    padding: 50px;

    .warning_container {
        text-align: left;
        margin: 20px 0;
    }

    .lgf_wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .lgf_container {
        align-self: center;
        text-align: center;
        // width: 300px;
    }
}