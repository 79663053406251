@import "Layout_v1/styles/variables";

.tab-wrapper{
    padding: 10px 20px 0 20px;
    font-size: 16px;
    flex-wrap: nowrap;

    .ant-tabs-nav{
        margin: 0;
    }

    .ant-tabs-nav-wrap{
        
        .ant-tabs-nav-list{
            .ant-tabs-tab{
                border: 1px solid rgb(228, 228, 228);
                border-radius: 5px;
                border-bottom: none;
                padding: 8px 15px 8px 15px;
                font-size: 14px;
                &.ant-tabs-tab-active{
                    background-color: white;
                    border-radius: 3px;
                }

            }

        }

        .ant-tabs-nav{
            padding: 0;
        }

    }

    .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar, .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar{
        display: none;
        // top:0;
        // bottom: auto;
    }

}

.filter-selection-list{
    padding: 0;
    margin: 0;
    list-style: none;
    max-height: 200px;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: #FFFFFF;

    border-bottom: 1px solid #EEE;

    
    li{
        background-color: #F5F8FA;
        max-width: 300px;

        border: 1px solid #EEE;
        border-radius: 3px;
        list-style: none;
        margin: 5px;
        padding: 5px 10px 0px 10px;

        .ant-checkbox-wrapper{
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}

.tab-setting-pop-overlay{
    .ant-popover-inner{
        // background-color: #F5F8FA;
        .ant-popover-inner-content{
            padding: 10px;

        }
    }
}



.filters_to_apply{
    .filter_container{
        border: $list-item-border;
        padding: 10px 10px;
        border-radius: 3px;
        margin-bottom: 5px;
        &:hover{
            background-color: $list-item-hover;
        }
        &:hover .actions{
            opacity: 1;
        }
        .title{
            a{
                font-weight: bold;
            }
        }
        .actions{
            opacity: 0;
        }
    }
}



/******* Editable Filter ********/
.edditable_filters_to_apply{
    margin-bottom: 5px;

    .filter_container{
        // border: $list-item-border;
        border-radius: 3px;
        padding: 10px 10px;
        margin-right: 10px;
        font-size: 14px;
        &:hover{
            background-color: $list-item-hover;
        }
        .title{
            a{
                font-weight: bold;
            }
        }
        .actions{
            margin-right: -10px;
            margin-left: 10px;
        }
    }

}
.editable-filter-pop-overlay{
    // .ant-popover-content{
    //     .ant-popover-arrow{
    //         border-color: $selected;
    //     }
    // }
    .ant-popover-inner{
        // .ant-popover-title{
        //     background-color: $selected;
        //     padding: 10px;
        // }

        .ant-popover-inner-content{
            // min-width: 100px;
            padding: 10px;
        }
    }

}

