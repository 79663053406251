$first_color: rgb(73, 138, 255);
$page_bg: #FFF;
$background_color: #f0f2f5;

$primary-color: #1890FF;
$primary-color-hover: #1845FF;

$priority-low-color: black;
    $priority-low-bg-color: #EEE;
$priority-normal-color: black;
    $priority-normal-bg-color: white;
$priority-high-color: white;
    $priority-high-bg-color: rgba(255, 0, 0, 1);


// List item 
$list-item-border: 1px solid #DFE3EB;
$list-item-hover: rgb(234, 240, 246);

$selected: #EAF0F6;
