.form-data{
     body {
          font-family: 'Roboto', sans-serif;
          margin: 0 auto;
          background-color: whitesmoke;
     }

     .dark, .simple{
          margin-top: 10px;
          padding: 10px;
          border-radius: 5px;
     }

     .dark {
          background-color: #EBECED;
     }

     .addmore {
          background-color: #060079;
          font-size: 15px;
          color: white;
          padding: 5px;
          border-radius: 5px;
          cursor: pointer;
     }

     .bold {
          font-weight: bold;
     }

     .uppercase {
          text-transform: uppercase;
     }

     .ml {
          margin-left: 10px;
     }

     .up {
          height: 40px;
          width: auto;
          border-radius: 4px;
          background-color: #e6bf36;
          text-align: center;
          color: #fff;
          font-size: 14px;
          transition: 0.3s ease;
          cursor: pointer;

     }

     .up:hover {
          background-color: #060079;
     }

     .flex {
          display: flex;
          flex-direction: row;
     }

     .white {
          background-color: whitesmoke !important;
     }

     .upload {
          width: 40%;
          border: 2px solid#e6bf36;
          padding: 30px;
          border-radius: 10px;
          display: flex;
          background-color: #060079;
          align-items: center;
     }

     /*header.jsx*/
     .row {

          display: flex;
          flex-wrap: wrap !important;
          align-items: center;
          color: white;
          background-color: #001529;
          /* text-align: center; */
     }

     .wrap {
          display: flex;
          flex-wrap: wrap !important;
     }

     .image-container {

          height: 80%
     }

     .image-container img {
          background-image: none;
     }

     .card-container {
          margin: 0px auto;
          width: 75%;
          height: 30vh;
          color: white;
          background-color: #060079;
          padding: 20px;
          border-radius: 2px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-align: center;
          box-shadow: 1px 1px 2px #060079;
          line-height: 1.3;
     }

     .card-container p {
          font-size: 12.8px;
          margin-bottom: 0;

     }

     .cyan {
          color: #e6bf36;
     }

     /*Applicant_form.jsx*/

     .card {
          margin: 0 auto;
          height: auto;
          background-color: white;
          padding: 20px;
          border-radius: 2px;
          color: #001529;
          border-radius: 5px;
     }

     .line {
          margin-top: 20px;
          border: 2px solid #e6bf36;

          margin-bottom: 0;
          border-radius: 5px;
     }

     .no-padding {
          padding: 0 !important;
     }


     .preview_card {
          margin: 0 auto;
          width: 100%;
          height: auto;
          background-color: #FFF;
          padding: 20px;
          border-radius: 2px;
          color: #001529;
          border-bottom-left-radius: 5px;
          border-bottom-right-radius: 5px;
     }

     .hover {
          cursor: pointer;
          padding: 10px;
     }

     .hover:hover {
          background-color: #EEE;
     }



     @media screen and (max-width: 625px) {
          .form {
               width: 100% !important;
               overflow: hidden;
          }

          .size {
               font-size: 13px;
               padding: 20px;
               text-align: center !important;
          }

          .add {
               margin-top: 0 !important;
          }
     }

     @media screen and (max-width: 676px) {

          .size {

               font-size: 13px !important;
               padding: 20px !important;
               text-align: center !important;
          }

          .add {
               margin-top: 0 !important;
          }

     }

     @media screen and (max-width: 785px) {

          .row{
               text-align: center;
          }

     }
}
