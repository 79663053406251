@import '~antd/dist/antd';
@import "variables";

.site-drawer-render-in-current-wrapper {
  position: relative;
  // height: 200px;
  // padding: 48px;
  overflow: hidden;
  // text-align: center;
  // background: #fafafa;
  // border: 1px solid #ebedf0;
  border-radius: 5px;
}


/********* Button Theme *********/
.btn_green {
  background: green;
  border-color: green;
  color: #FFF;

  &:hover, &:focus {
    background: rgba(green, 0.8);
    border-color: rgba(green, 0.8);
    color: #FFF;
  }
}

.btn_orange {
  background: orange;
  border-color: orange;
  color: #ffffff;

  &:hover, &:focus {
    background: rgba(orange, 0.8);
    border-color: rgba(orange, 0.8);
    color: #ffffff;
  }
}

.btn_grey {
  background: #EEE;
  border-color: rgba(72, 72, 72, 0.1);
  color: rgb(72, 72, 72);
  
  &:hover, &:focus {
    background: rgba(#EEE, 0.8);
    border-color: rgba(72, 72, 72, 0.3);
    color: #000;
  }
}

.btn_lightgrey {
  background: #EFF2F5;
  border-color: rgba(72, 72, 72, 0.1);
  color: rgb(72, 72, 72);
  
  &:hover, &:focus {
    background: rgba(#FFF, 0.8);
    border-color: rgba(72, 72, 72, 0.3);
    color: #000;
  }
}

.btn_red {
  background: red;
  border-color: red;
  color: #FFF;

  &:hover, &:focus {
    background: rgba(red, 0.8);
    border-color: rgba(red, 0.8);
    color: #FFF;
  }
}

.btn_white {
  background: white;
  border-color: #cbcbcb;
  color: #666666;

  &:hover, &:focus {
    background: rgba(rgb(234, 234, 234), 0.8);
    border-color: rgba(rgb(121, 121, 121), 0.8);
    color: #000;
  }
}

.btn_white {
  background: transparent;
  border-color: transparent;
  color: #565656;

  &:hover, &:focus {
    background: rgba(rgb(234, 234, 234), 0.8);
    border-color: rgba(rgb(121, 121, 121), 0);
    color: #1B90FF;
  }
}





/*********** Drawer ********/
.ant-drawer-header{
  background-color: #002140;
  .ant-drawer-title, .ant-drawer-close{
    color: #FFFFFF;
  }
}


/******** Modal *******/
.ant-modal-header{
  background-color: #002140;
  .ant-modal-title{
    color: #FFFFFF;
  }
}
.ant-modal-content .ant-modal-close{
  color: #FFF;
}

.user-card-popover{
  // background-color: #002140;
  // color: white;
}


.upload-list{
  .ant-upload-list-item-name{
    text-overflow: ellipsis;
    overflow: hidden;
    overflow: ellipsis;
    width: inherit;
  }
}