.course_screen{
     li{
          margin-bottom: 15pt;
     }
}



.inline_select{
     display: inline-block;
     border: 0px solid blue;
     border-bottom: 1px solid rgba(0,0,255, 0.5);
     border-radius: 5px;
     margin: 0 5px;
     font-size: 16px;
     min-width: 200px;
}

.inline_select > .ant-select-selector{
     border: 0px solid green !important;
}




/* body {
     font-family: 'Roboto', sans-serif;
     margin: 0 auto;
     background-color: whitesmoke;
} */

.upload {
     width: 40%;
     border: 2px solid#e6bf36;
     padding: 30px;
     border-radius: 10px;
     display: flex;
     background-color: #060079;
     align-items: center;
}

/*header.jsx*/
.image-container {
     height: 80%
}

.image-container img {
     background-image: none;
}

.card-container {
     margin: 0px auto;
     width: 75%;
     height: 30vh;
     color: white;
     padding: 20px;
     border-radius: 2px;
     display: flex;
     flex-direction: column;
     justify-content: center;
     align-items: center;
     text-align: center;
     box-shadow: 1px 1px 2px #060079;
     line-height: 1.3;
}

.card-container p {
     font-size: 12.8px;
     margin-bottom: 0;

}

.cyan {
     color: #e6bf36;
}

/*Applicant_form.jsx*/
.card {
     margin: 0 auto;
     height: auto;
     background-color: white;
     padding: 20px;
     color: #001529;
     border: 1px solid #CCC;
     border-top: 4px solid #e6bf36;
     border-bottom: 4px solid #CCC;
     border-radius: 10px;
     margin-bottom: 20px;
}

.line {
     margin-top: 20px;
     border: 2px solid #e6bf36;

     margin-bottom: 0;
     border-radius: 5px;
}

.no-padding {
     padding: 0 !important;
}

// input {
//      margin-bottom: 10px;
// }




.preview_card {
     margin: 0 auto;
     width: 100%;
     height: auto;
     background-color: #FFF;
     padding: 20px;
     border-radius: 2px;
     color: #001529;
     border-bottom-left-radius: 5px;
     border-bottom-right-radius: 5px;
}

.hover {
     cursor: pointer;
     padding: 10px;
}

.hover:hover {
     background-color: #EEE;
}



@media screen and (max-width: 625px) {
     .form {
          width: 100% !important;
          overflow: hidden;
     }

     .size {
          font-size: 13px;
          padding: 20px;
          text-align: center !important;
     }

     .add {
          margin-top: 0 !important;
     }
}

@media screen and (max-width: 676px) {

     .size {

          font-size: 13px !important;
          padding: 20px !important;
          text-align: center !important;
     }

     .add {
          margin-top: 0 !important;
     }

}

@media screen and (max-width: 785px) {

     .row{
          text-align: center;
     }

}