@import 'antd';
@import "variables";

// .anticon{ color: none !important; }


body {
  font-size: 14px;
  display: block;
}

hr{
  border: 1px solid #EEE;
}

#root {
  // padding: 0;
  border: 0px solid blue;
  // width: 100%;
  // min-width: 100vw;
  max-width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  // overflow: hidden;
  overflow-x: hidden;
  // flex-wrap: wrap;
  // max-width: 100vw;
}

.sidebar-logo {
  padding: 10px;
  text-align: center;
  width: 100%;

  img {
    align-self: center;
    width: 100%;
    max-width: 70px;
  }
}

button.anchor_button {
  margin: 0;
  padding: 0;
  font-size: 1em;
  height: auto;
  line-height: 1;
}

.a, a {
  color: $primary-color;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
    color: $primary-color-hover;
  }
}

.transparent_bg {
  background-color: transparent !important;
}

/************************
* Headings
*****************/
.heading {
  color: #498aff;
  .ant-divider-inner-text {
    // font-weight: bold;
    color: #498aff;
    // color: #999;
  }
}

.ant-divider-horizontal.ant-divider-with-text::before,
.ant-divider-horizontal.ant-divider-with-text::after {
  // border-top: 3px solid #498aff;
  border-top: 5px solid #EEE;
}

h1, h2, h3, h4, h5, h6 {
  &:not([class^="ant-skeleton"]) {
    display: flex;
    color: #498aff;
    padding: 0px 0px 0px 0px;
    margin: 0px 0px 30px 0px;
    // border-bottom: 2px solid #aacce6;
    justify-content: space-between;
  }

  .buttons {}
}

h1 { font-size: 1.0em; }
h2 { font-size: 1.2em; }
h3 { font-size: 1.4em; }
h4 { font-size: 1.6em; }
h5 { font-size: 1.8em; }
h6 { font-size: 2.0em; }

label{ color: #787878; }

.gray1 { color: #2e2e2e; }
.gray2 { color: #636363; }
.gray3 { color: #a3a3a3; }

.nowrap{ flex-wrap: nowrap; }

.info_tag {
  display: inline-block;
  padding: 0px 5px;
  border: 1px solid #EEE;
  border-radius: 5px;
  
  &.small{
    font-size: 10px;
  }

  &.red {
    color: white;
    background-color: red;
  }
  &.gray {
    color: white;
    background-color: #999;
  }
}

.moticon_button {
  cursor: pointer;
  font-size: 24px;

  &:hover {
    color: #1B90FF;
  }
}

.reaction_wrapper{
  position: absolute;
  right: 10px;
  bottom: -5px;
  border: 1px solid #EEE;
  background-color: white;
  border-radius: 20px;
  padding: 0 5px 0 10px;
  z-index: 999;
  scale: 0.8;
  
  &:hover{
    scale: 1;
  }
}
.reactions_row{
  display: inline-flex;
  text-align: right;

  .reaction_moticon{
    // cursor: pointer;
    font-size: 20px;
    transition: 100ms;
    margin-bottom: -2px;

    margin-left: 0px;

    &:hover {
      color: #1B90FF;
      margin-left: 0px;
      padding-left: 3px;
      &:not(:last-child) {
        padding-right: 8px;
      }
    }
  }

}

/**********
* Site Layout
******/
.site-layout { }

.site-sidebar {}

.site-layout-content {
    background-color: #EFF2F5;
}

.site-layout-header {
  color: #FFF;
  padding: 0;
  height: auto;
  line-height: 40px;

  .column-right {
    text-align: right;
  }
}

.site-content {
  background-color: $page_bg;
  margin: 10px 10px;
  padding: 15px;
  // min-height: 280px;
  border-radius: 5px;

  &.transparent {
    background-color: transparent;
  }
}

.site-footer {
  text-align: center;
}

.dev_block {
  text-align: left;
  border-radius: 3px;
  padding: 3px;
  border: 1px solid #00F;
  background-color: #F3F3F3;
  line-height: 1.1em;
  color: #00F !important;
  font-size: 16px !important;
  font-family: 'Times New Roman', monospace !important;
}



.grid-block {
  padding: 10px;
  margin: 10px;
  background-color: #FFF;
  border-radius: 3px;
}



/******* 
Form
********/
.blue .ant-select-selector {
  background-color: #498aff !important;
  color: #FFF;
}

.active .ant-select-selector {
  background-color: #308a31 !important;
  color: #FFF;
}

.inactive .ant-select-selector {
  background-color: #ac0011 !important;
  color: #FFF;
}

.inline-label{
  color: #999;
}
.form-field-label,
.form_label,
.form-field label {
  text-transform: capitalize;
  display: block;
  clear: both;
  width: 100%;
  font-size: 1em;
  color: #555;

  &.isHorizontal{
    display: inline;
    margin-right: 20px;
  }
}

.simple-field .form-field-label {
  margin-left: 3px;
}

.form-field {
  &.linethrough input {
    text-decoration: line-through;
  }

  &.field-margins {
    margin: 10px;
  }

  &.switch {
    label {
      clear: none;
      width: auto;
      display: inline-block;
      padding: 3px 0 0 5px;
      // border: 1px solid #000;
    }

    .ant-switch {
      float: left;
    }
  }

  &.checkbox{
    .ant-checkbox-wrapper{
      display: flex;
      flex-direction: row;
      flex-wrap:nowrap;
    }

  }

  &.has-errors{
    .ant-select, .ant-select .ant-select-selector, 
    // input:not(.ant-picker-input), 
    input, textarea,
    .ant-picker{
      // border: 1px solid rgb(255, 153, 0);
      border-color: rgb(255, 214, 152);
      background-color: rgb(255, 255, 230);
      .ant-select-selector{
        background: none;
      }
    }
  }

  &.date-range{
    .ant-picker-range{ width:100%; }
  }
}

.field-error {
  clear: both;
  font-size: 0.8em;
  color: #F00;
  animation-name: FadeInFromTop;
  animation-duration: 0.3s;
}

.form-field.file-upload{
  &.disabled{
    opacity: 0.5;
  }
}


.form-field.validate.file-upload,
.form-field.validate.input-number input,
.form-field.validate.input input, 
.form-field.validate.date .ant-picker, 
.form-field.validate.input textarea, 
.form-field.validate.select .ant-select{
  border-left: 2px solid red;
  border-radius: 3px;
}

.form-field.html {
  .field-html-toolbar {
    a {
      color: unset;
    }
  }

  .field-html-editor {
    margin-top: -5px;
    border: 1px solid #EEE;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    padding: 0 10px !important;
  }
}

.upload-item-list{
  border: 1px solid #EEE;
  border-radius: 5px;
  &:hover{
    background-color: #EEE;
  }
}





/***** 
Drop Down style
*/
///
.dd-divider {
  display: inline;
  background-color: #EEE;
  color: #FFF;
  text-align: center;
}

/************
* Animation
**/
@keyframes FadeInFromTop {
  from {
    opacity: 0;
    margin-top: -10px;
  }

  to {
    opacity: 1;
    margin-top: 0px;
  }
}

@keyframes FadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes FadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}




/*************
* Drawer
***************/
.drawer-spread-footer {
  display: flex;
  justify-content: space-between;
}



/***********
* Table Component
*************/
.table-header{
  margin-top: 10px;
  padding: 0 10px 0 10px;
  border: 1px solid #EEE;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  background-color: rgba(0, 0, 0, 0.02);
}
.disabled-table-row {
  background-color: #EEE;
  color: #999;

  &:hover {
    color: #666;
  }
}
.yellow-table-row{
  background-color: rgba($color: yellow, $alpha: 0.1);
  // color: #999;

  &:hover {
    // color: #666;
  }

}
.hilighted-table-row {
  background-color: rgba(255, 255, 123, 0.2);

  // &:hover {
  //   background-color: rgba(255, 255, 123, 1);
  // }
}

.selected-table-row {
  background-color: rgba(0, 150, 0, 0.2);
  // color: #999;
  // &:hover {
  //   color: #666;
  // }
}

.table-row-red {
  background-color: rgba(255, 0, 0, 0.7);
  color: #FFF;

  &:hover {
    color: #F00;
  }
}

.show-menu-on-hover {
  position: relative;

  .hidden-menu {
    // border: 1px solid black;
    position: absolute;
    right: 0;
    top:-3px;
    display: none;
    opacity: 0;
    // width: 100%;
    height: 100%;

    &.left{
      left: 0;
      right: auto;
    }

    .item{ margin: 0 2px; z-index: 1; }
  }

  &:hover .hidden-menu {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: end;
    opacity: 1;
    transition: opacity .3s;
  }
}

.flag_normal{
}
.flag_invalid{
  background: rgba(255, 0, 0, 0.2);
}
.flag_canceled{
  opacity: 0.3;
  background: rgba(0, 0, 0, 0.5);
  text-decoration: line-through;
  // color: rgba(0, 0, 0, 0.2)
}

/***********
* delierySlotsTable Table
*************/
.delierySlotsTable {
  .day-title-column {
    color: #498aff;
    font-size: 1.2em;
    text-transform: capitalize;
  }

  // .header-row{
  //   font-weight: bold;
  // }
  // .timeslot-row{
  //   flex-direction: row;
  //   flex-wrap: nowrap;
  //   // overflow-x: scroll;    
  //   .timeslot-col{
  //     border-left: 1px solid #CCC;
  //     width:200px;
  //   }
  // }
}

/*************
* Upload Gallery 
*******************/
.upload-gallery {

  .gallery-item {
    display: flex;
    flex-direction: column;
    margin: 5px;
    padding: 5px;
    border: 1px solid #EEE;
    border-radius: 3px;
    justify-content: space-between;

    .loader-bar {
      display: flex;
      flex-direction: row;
      width: 100%;

      .progress-bar {
        margin-top: 3px;
      }

      .loader {
        margin: 5px;
      }
    }

    .thumbnail-holder {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      border: 1px solid #EEE;
      border-radius: 3px;

      .thumb-img {
        border-radius: 3px;
        min-width: 50px;
        min-height: 50px;
        max-width: 100%;
        max-height: 100%;
      }

      .hoverLayer {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 3px;
        width: 100%;
        height: 100%;
        background-color: rgba(000, 000, 000, 0.5);
        visibility: hidden;
        cursor: pointer;

        .preview-icon {
          position: absolute;
        }

        // .anticon{ display: none; size: 3em;}
        .anticon {
          display: inline;
          size: 5em;
          color: #FFF;
        }
      }
    }

    .thumbnail-holder:hover {
      .hoverLayer {
        visibility: visible;
        // .anticon{ display: inline; }
      }
    }

    .icon-holder {
      .delete-button {}
    }

  }

}

.upload-gallery.picture-card {
  .gallery-item {
    text-align: center;
  }
}

.upload-gallery.list {
  .gallery-item {
    text-align: center;
    flex-direction: row;
    align-items: center;

    .thumbnail-holder {
      width: 70px;
    }
  }
}

.upload-gallery.picture-grid {
  display: flex;
  flex-wrap: wrap;

  .gallery-item {
    text-align: center;

    .thumbnail-holder {
      width: 100px;
      height: 100px;
    }

  }
}




.data-row-table {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;

  &.grid {
    flex-wrap: wrap;
    flex-direction: initial;
  }
}

.date-row {
  white-space: nowrap;
  border: 1px solid #DDD;
  background-color: $background_color;
  border-radius: 3px;
  margin: 3px;
  padding: 5px 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 0.9em;
}





/************
* Delivery Slot Page
*************/
.ant-table-tbody {
  .timeslot-col {
    vertical-align: top;
    padding: 1px 1px !important;

    .action-bar {
      width: 100%;
      opacity: 0.5;
      // background-color: #EEE;
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;
    }

    .col-content {
      padding: 10px;

      .block-date-holder {
        text-decoration: line-through;
        white-space: nowrap;
        font-size: 0.9em;
        background-color: #F00;
        color: #FFF;
        padding: 1px 5px;
        margin: 3px;
        border-radius: 3px;
      }
    }
  }

  .timeslot-col:hover {
    .action-bar {
      opacity: 1;
      background-color: #EEE;
    }

  }

}




/*****************
*** Status form
***********/
.quick-status-form {
  margin: 0;
  padding: 0;

  .field-group {
    margin: 0;
    padding: 0;
    display: flex;

    justify-content: stretch;

    .note_field {
      width: 100%;
    }

    .status_field {}

    .send_button {}
  }

}







/***********************
* Data Grid
*************************/
.dataGrid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .ant-card-body {
    display: flex;
    // width: 100%;
    flex-direction: column;
    // justify-content: center;
    // align-items: center;
    // border: 1px solid #000;
    padding: 0px !important;
  }

  ._card {
    min-width: 150px !important;
    width: 150px !important;
    margin: 5px;

    &.card_status_hidden {
      background-color: #EEE;
    }

    &.ant-card-hoverable {
      cursor: default !important;
    }

    .card_body {
      padding: 5px;
      text-align: center;
    }

    .moreDD {
      position: absolute;
      z-index: 999;
      top: 10px;
      right: 10px;

      .moreButton {}
    }

    ._title {
      flex: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: center;
      font-weight: bold;
      // margin-bottom: 10px;
      overflow: ellipsis;
    }
  }
}


.pagination-filter {
  background-color: $background_color;
  border-radius: 3px;
  box-sizing: border-box;
  // display: flex;
  // padding: 5px 10px;
  // align-items: center;
  // justify-content: space-between;

  .pagination-filter-row {
    box-sizing: border-box;
    flex-wrap: nowrap;
    // padding: 5px 10px;
  }

  .filters-column {
    display: flex;
    flex-wrap: wrap;
    padding: 5px 10px;
  }

  .go-column {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    padding: 10px;
    background-color: #CCC;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    // flex-direction: column;
    // flex-wrap: nowrap;
  }
}





/**************
basket
***********/
.basketLab {
  display: inline-block;
  border: 1px solid #CCCCCC;
  border-radius: 5px;
  // background-color: #00FF00;

  .label {
    // background-color: #00FF00;
    font-size: 40px;
    text-align: center;
  }

  .barcode {}
}











/**********************
Template Documnet Styles
***********************/
.inputField {
  background-color: #CCCCCC;
  border-radius: 3px;

  &.required {
    border: 1px solid orange;
  }

  &.editable {
    background-color: #BEE7FF;
  }
}




/**********************
Drag & Drop
***********************/
.dnd_container {
  background: #dadada;
  padding: 0;
  margin: 0;
  flex: 1;
  border: 1px solid #FFF;
  min-height: 100px;
}

.dnt_item {
  width: 100%;
  min-height: 50;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #CCC;
  border-top: 1px solid #FFF;
  border-right: 1px solid #FFF;

  padding: 10px 0;
  background: rgba(255, 255, 255, 0.5);
  cursor: move;
}





.dataTable{
  min-width: 100%;
  border: 1px solid #999;
  thead{
    background-color: #EEE;
    tr th{
      border: 1px solid #999;
    }
  }
  tbody tr td{
    border: 1px solid #999;
    text-align: center;
  }
}






/************ Noticeboard ********/
.noticeboard_priority_tag{
  display: inline-block;
  padding: 0px 5px;
  border: 1px solid #EEE;
  border-radius: 5px;

  &.low{
    color: $priority-low-color;
    background-color: $priority-low-bg-color;
  }
  &.normal{
    color: $priority-normal-color;
    background-color: $priority-normal-bg-color;
  }
  &.high{
    color: $priority-high-color;
    background-color: $priority-high-bg-color;
  }
}

.noticeboard{
  width: 100%;
  // border: 0px solid black;
  margin-top: 10px;
  display: inline-block;

  .noticeboard_note{
    // border: 5px solid red;
    cursor: pointer;
    float: left;
    display:inline-flex;
    flex-direction: column;
    flex-wrap:wrap;
    flex-flow:column;
    position: relative;

    border-radius: 5px;
    margin:10px;
    padding:10px;
    width:300px;
    // color: coral;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19) !important;
    transition-duration: 300ms;
    
    &:hover{
      box-shadow: 0 10px 16px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19) !important;
    }

    .note_expired{
      background-color: red;
      margin-top: -10px;
      margin-left: -10px;
      margin-right: -10px;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      text-align: center;
      color: white;
      border-bottom: 1px solid white;
    }

    .hover_menu{
      position: absolute;
      top: 0px;
      right: 0px;
      opacity: 0;
      display: none;
    }
    &:hover .hover_menu{
      opacity: 1;
      display: inline;
    }


    &.low{
      background-color: $priority-low-bg-color;
    }
    &.normal{
      background-color: $priority-normal-bg-color;
    }
    &.high{
      background-color: $priority-high-bg-color;
      color: $priority-high-color;
    }

    .priority{
      text-align: right; 
      font-weight: bold;
      &.low{ color: $priority-low-color; }
      &.normal{ color: $priority-normal-color; }
      &.high{ color: $priority-high-color; }
    }
    .title{ font-weight: bold; }

    .body{
      // white-space: nowrap;
      max-height:250px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .footer{
      border-top: 1px solid #999;
      // text-align: right;
      font-size: 11px;
    }

  }

}

// box-shadow

.shadow{
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19) !important;
}






/******** Address popover *********/
.addressbook-pop-overlay{
  .ant-popover-content{
    // border: 1px solid green;
    .ant-popover-inner{
      // border: 1px solid blue;
      .ant-popover-inner-content{
        border: 1px solid #F0F0F0;
        padding: 0px;

        .search-bar{
          background-color: #EFF2F5;
          padding: 10px;
        }

        .list-body{
          padding: 10px;
          max-height: 300px;
          overflow-y: auto;
        }
      }
    }
  }
}
.addressbook-pop-overlay-inner{

}






/************
List
***********/
.general_list{
  .title{
    font-weight: bold;
    font-size: 16px;
  }

  .g_list_item{
    cursor: pointer;
    color: #34485B;
  
    &:hover{
      background-color: #F5F8FA;
    }
  
    padding: 6px 5px;
  }
}





/****** Text Handler *******/
.ellipsis{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  width: 100px;
}






/******** Table ********/
.data-table{
  border: 1px solid #EEE;
  width: 100%;
  th{
    background-color: #EEE;
  }
  tr{
    border-bottom: 1px solid #EEE;
    &:hover{
      background-color: #FAFAFA;
    }
  }
  td{
    padding: 5px 5px;
    border-right: 1px solid #EEE;
  }
}



/*********** DATA ROW ********/
.data-row{
  padding: 5px 0;
  border: 1px solid rgba(0, 0, 0, 0.0);

  &:hover{
    border-color: #EEE;
  }

  &.inline{
    display: flex; 
    flex-direction: row;
    .col1{
      padding-right: 5px;
      border-right: 1px solid rgb(205, 205, 205);
    }
    .col1, .col2{ display: inline-block; }
  }
  .col1{
    margin-right: 5px;
    color: #999; //  #3a3686;
  }
  .col2{
    // color: ;
  }
}



/***************** Custom Tabs **********/
.custom-tabs{
  // border-bottom: 1px solid black;
  padding-bottom: 2px;
  font-size: 14px;
  
  .tab-item{
    cursor: pointer;
    border: 1px solid #EEE;
    border-bottom: none;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    background-color: rgba($color: #FFF, $alpha: 0.3);
    padding: 5px 15px;
    &.active{
      color: #1B90FF;
      background-color: rgba($color: #FFF, $alpha: 1.0);
    }

    transition: all .5s;
  }

  .tab-item:not(.active):hover{
      color: rgba($color: #1B90FF, $alpha: 1.0);
      border-color: rgba($color: #1B90FF, $alpha: 0.2);
      background-color: #EEE;
  }

  &.small{
    padding-bottom: 7px;
    font-size: 12px;
    .tab-item{
      padding: 10px 15px;
    }
  }
  &.large{
    padding-bottom: 7px;
    font-size: 16px;
    .tab-item{
      padding: 10px 25px;
    }
  }
  &.big{
    font-size: 18px;
  }

}








.btn-show-on-hover{
  cursor: pointer;
  opacity: 0;
  &:hover{
    opacity: 1;
  }
}




.service-tag{
  border: 1px solid #DDD;
  border-radius: 10px;
  background-color: #EEE;
  padding: 0 10px;
  margin: 5px;
}





// .pdf_page{
//   background-size: contain;
//   background: lightblue url("/bg-letterhead.jpg") no-repeat fixed center;
  
// }






/**************
Threads
************/
.threads-message-header{
  &.closed{
    background-color: #999;
    color: white;
  }
}

.threads-message{
  border: 0.1px solid #DDD;
  position: relative;
  padding: 8px 8px;
  // margin-bottom: 0px;
  margin: 0;

  &.compact{
    padding: 8px 8px;
    // border: 1px solid blue;
    line-height: 14px;
  }

  &.status_archived{
    text-decoration: line-through;
    background-color: #2d2d2d;
    color: white;
  }

  &.isNew{
    background-color: rgb(253, 253, 234);
  }

  &:hover{
    // background-color: #F3F3F7;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
  }
  
  .hover_menu {
    background-color: white;
    border: 1px solid #EEE;
    border-radius: 5px;
    // padding: 0 10px;
    position: absolute;
    top: 5px;
    right: 5px;
    opacity: 0;
    display: none;
    z-index: 999;

    .menu-btn{
      padding:0px 8px;
    }

    .menu_icon{
      font-size: 18px;
      cursor: pointer;
      transition: 500ms;
      color: #565656;
      &:hover{
        color: #1B90FF;
      }
    }
  }

  &:hover .hover_menu {
    opacity: 0.5;
    display: inline;
    &:hover{
      opacity: 1;
    }
  }

  .replyCaptured {
    margin-bottom: 5px;
    cursor: pointer;
    .imgHolder {
      display: inline-block;
      border: 1px solid #999999;
      border-radius: 5px;
      width: 100%;
      background-color: #EEE;
      overflow: hidden;

      .image {
        // width: 100%;
        // max-width: 500px;
        height: 50px;
      }

    }

    &:hover {
      .imgHolder{
        border-color: #00F;
      }
    }
  }
}

.thread-message-replyForm{
  .replyCaptured{
    .imgHolder{
      display: inline-block;
      border: 1px solid #999999;
      border-radius: 5px;
      width: 100%;
      background-color: #EEE;
      .image{
        // width: 100%;
        // max-width: 500px;
        height: 50px;
      }

    }
  }
}

.threads_menu_item{
  width: 100%;
  margin-bottom: 5px;
  .icon{
    font-size: 10px;
  }
  .new_badge{
    background-color: red;
    border-radius: 50%;
    color: white;
    font-size: 10px;
    padding: 2px 5px;
  }
  .priority_badge{
    background-color: red;
    border-radius: 50%;
    color: white;
    font-size: 10px;
    padding: 2px 6px;
  }

  &.selected{
    background-color: #EEE;
    border-radius: 5px;
  }
}







/**************
Todo
************/
.todo-message {
  border: 0.1px solid #DDD;
  position: relative;
  padding: 8px 8px;
  margin: 0;

  &.compact {
    padding: 8px 8px;
    line-height: 14px;
  }

  &.status_canceled {
    text-decoration: line-through;
    background-color: #EEE;
    color: #999;
  }

  &.status_completed {
    // text-decoration: line-through;
    background-color: #c9efd7;
    // color: white;
  }

  &:hover {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
  }

  .body:not(.disabled){
    cursor: pointer;
    &:hover{
      background-color: rgb(228, 228, 252);
    }
  }

}






/************ SMS styles ****************/
.sms_userinfo{
  background-color: #1B90FF;
  color: #FFF;  padding: 1px 5px;
  border-radius: 5px;
  font-size: 11px;
  cursor: pointer;

  &:hover{
    background-color: #03386a;
  }
}
.sms_msg_number{
  background-color: #EEEEEE;
}
.is_my_message{
  text-align: right;
  direction: rtl;
}






/**************
Attendnace
************/
.attendance-column {
  cursor: pointer;
  display: inline-block;
  color: white;
  border-radius: 10px;
  padding: 0px 5px;
  margin: 0 5px 2px 0;
  font-size: 12px;

  &.in{
    background-color: green;
  }
  
  &.out{
    background-color: red;
  }

  &:hover{
    background-color: #1B90FF;
  }
}








.alertButton {
  margin-top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1790FF;
  width: 30px;
  height: 30px;
  border-radius: 50px;
  font-size: 20px;
  color: #FFF;
  cursor: pointer;

  &:hover{
    background-color: #7bbfff;
  }
}
.alert-list-item{
  width: 100%;
  padding: 10px 10px;
  margin: 1px 0;
  transition: 0.5s;

  &.new{
    background-color: #FFFFE6;
  }

  &:hover{
    background-color: #EEE;
  }
}